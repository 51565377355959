.pagination {
  display: flex;
  margin-top: 2 * $gutter;

  li {
    align-self: center;
    margin-right: $gutter;

    a {
      color: lighten($grey, 5%);
      line-height: 1;
    }

    &.pagination__current,
    &:not(.pagination__nav) a {
      @include rounded(45px);
      font-family: $font-primary-semibold;
      font-size: 0.85em;
      transition: all 0.2s ease;

      &:active,
      &:focus,
      &:hover {
        text-decoration: none;
      }

      &:hover:not(.pagination__current) {
        background: $dark-yellow;
        color: #fff;
      }
    }

    &.pagination__current {
      background: $blue;
      color: #fff;
      font-size: 1em;
    }
  }
}
