.content-list__item {
  &:not(:last-child) {
    margin-bottom: $block-spacing * 0.5;

    @include tablet-landscape {
      margin-bottom: $block-spacing;
    }
  }

  .content-list__item-image {
    @include until-tablet {
      padding-bottom: 0;
    }
  }

  .content-list__item-title {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 1.5em;
    overflow: hidden;

    @include mobile-landscape {
      font-size: 1.75em;
    }

    @include tablet-landscape {
      font-size: 2em;
    }

    @include desktop {
      font-size: 2.3125em;
    }
  }

  .content-list__item-date {
    color: $grey;
    font-size: $font-size-small;
  }
}
