[class*="icon__"],
.icon {
  position: relative;
  background-repeat: no-repeat;
  display: inline-block;
  background-size: contain;
}

.icon__biceps {
  width: 1.333em;
  height: 1.333em;
  background-image: url("/assets/icons/biceps.png");
  top: -0.1em;
  vertical-align: middle;
}
