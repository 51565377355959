$font-primary: "MazzardH-Regular";
$font-primary-medium: "MazzardH-Medium";
$font-primary-semibold: "MazzardH-SemiBold";
$font-primary-bold: "MazzardH-Bold";
$font-primary-italic: "MazzardH-Italic";

$font-size-small: 0.875em;

// BASIC FALLBACK FONTS
$ff-sans-serif-fallback: "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif;
$ff-serif-fallback: "Times", "Times New Roman", serif;
$ff-mono-fallback: "Monaco", "Courier New", "Courier", monospace;

// PROJECT FONTS
$ff-metric-hpe: "MetricHPE", $ff-sans-serif-fallback;
$ff-libre-baskerville: "Libre Baskerville", $ff-serif-fallback;
$ff-roboto-mono: "Roboto Mono", $ff-mono-fallback;

$webfont-path: "/assets/webfonts";

@font-face {
  font-family: "MazzardH-Regular";
  src: url("#{$webfont-path}/3ADE41_3_0.eot");
  src: url("#{$webfont-path}/3ADE41_3_0.eot?#iefix") format("embedded-opentype"),
    url("#{$webfont-path}/3ADE41_3_0.woff2") format("woff2"), url("#{$webfont-path}/3ADE41_3_0.woff") format("woff"),
    url("#{$webfont-path}/3ADE41_3_0.ttf") format("truetype");
}

@font-face {
  font-family: "MazzardH-Italic";
  src: url("#{$webfont-path}/3ADE41_1_0.eot");
  src: url("#{$webfont-path}/3ADE41_1_0.eot?#iefix") format("embedded-opentype"),
    url("#{$webfont-path}/3ADE41_1_0.woff2") format("woff2"), url("#{$webfont-path}/3ADE41_1_0.woff") format("woff"),
    url("#{$webfont-path}/3ADE41_1_0.ttf") format("truetype");
}

@font-face {
  font-family: "MazzardH-Medium";
  src: url("#{$webfont-path}/3ADE41_2_0.eot");
  src: url("#{$webfont-path}/3ADE41_2_0.eot?#iefix") format("embedded-opentype"),
    url("#{$webfont-path}/3ADE41_2_0.woff2") format("woff2"), url("#{$webfont-path}/3ADE41_2_0.woff") format("woff"),
    url("#{$webfont-path}/3ADE41_2_0.ttf") format("truetype");
}

@font-face {
  font-family: "MazzardH-SemiBold";
  src: url("#{$webfont-path}/3ADE41_4_0.eot");
  src: url("#{$webfont-path}/3ADE41_4_0.eot?#iefix") format("embedded-opentype"),
    url("#{$webfont-path}/3ADE41_4_0.woff2") format("woff2"), url("#{$webfont-path}/3ADE41_4_0.woff") format("woff"),
    url("#{$webfont-path}/3ADE41_4_0.ttf") format("truetype");
}

@font-face {
  font-family: "MazzardH-Bold";
  src: url("#{$webfont-path}/3ADE41_0_0.eot");
  src: url("#{$webfont-path}/3ADE41_0_0.eot?#iefix") format("embedded-opentype"),
    url("#{$webfont-path}/3ADE41_0_0.woff2") format("woff2"), url("#{$webfont-path}/3ADE41_0_0.woff") format("woff"),
    url("#{$webfont-path}/3ADE41_0_0.ttf") format("truetype");
}
