$maxcols: 12;
$gutter: 0.75em;
$double-gutter: $gutter * 2;
$tripple-gutter: $gutter * 3;

$content-max-width-mobile: 410px;
$content-max-width-desktop: 1165px;
$content-max-outer-width: 1440px;

// TRANSITION SPEED
$transition-fast: 0.2s;
$transition-slow: 0.8s;
$transition-very-slow: 2s;
$transition-sluggish: 4s;

$block-spacing: 2.75em;

$outer-hpadding-mobile: 2 * $gutter;
$outer-hpadding-mobile-landscape: 3 * $gutter;
$outer-hpadding-tablet: 4 * $gutter;
$outer-hpadding-desktop: 5 * $gutter;

// Margin top offset for scrolling to an anchor. @see ../utils/_anchor-scroll
$anchor-scroll-mt: 100px;
