.cta-box {
  @include gitter-bg;
  color: $blue;
  padding: 3.375em $outer-hpadding-mobile;

  @include mobile-landscape {
    padding-left: $outer-hpadding-mobile-landscape;
    padding-right: $outer-hpadding-mobile-landscape;
  }

  @include tablet {
    padding-left: 0;
    padding-right: 0;
  }

  .is-vertical {
    .column {
      &:first-of-type {
        margin-bottom: $gutter;
      }

      .button {
        display: block;
        max-width: $mobile-small-max;

        @include tablet {
          margin-left: auto;
        }
      }
    }
  }
}
