html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
em,
img,
ul,
ol,
li,
form,
label,
button,
input,
select,
textarea,
canvas {
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  border-color: #000;
  border-style: solid;
  float: none;
  font: inherit;
  margin: 0;
  padding: 0;
  text-align: inherit;
  vertical-align: baseline;
  white-space: inherit;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: $font-primary;
  font-size: 16px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

body {
  background: $white;
  @include tablet {
    @include gitter-bg;
  }
}

button,
input,
select,
textarea {
  font-family: $font-primary;
  font-size: 16px;
}

main,
main.container {
  @include tablet {
    min-height: 15em;
    margin-top: -15em;
  }

  @include tablet-landscape {
    min-height: 30em;
    margin-top: -30em;
  }
}

div,
main {
  position: relative;
}

ul {
  list-style: none;
}

h2 {
  font-size: 2.425em;
}

img {
  display: block;
}

a {
  color: $blue;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
}
