.block {
  &__testimonial {
    &.with-image {
      @include tablet {
        padding-left: 0;
      }

      .column:first-of-type {
        display: none;

        @include tablet {
          display: block;
          margin: (3 * $gutter * -1) 0;
        }

        @include desktop {
          margin: (4 * $gutter * -1) 0;
        }
      }
    }

    .tag {
      margin-bottom: 3.75em;
    }

    h1 {
      margin-bottom: $gutter;
    }
  }
}
