.block.block__title {
  padding-bottom: 1.5 * $gutter;
  padding-top: 1.5 * $gutter;

  .tag {
    margin-bottom: 1.5 * $gutter;
  }

  .description {
    margin-top: 1.5 * $gutter;
    margin-bottom: 3 * $gutter;
  }

  @include desktop {
    padding-bottom: 3.5 * $gutter;
    padding-top: 3.5 * $gutter;
  }
}

.block__title__date {
  color: $light-grey;
  font-size: 0.85em;
  padding-top: $gutter;
}

.with-background .block__title__date {
  color: $white;
}

//Toolbox specifick
.toolboxContent {
  .tag {
    margin-top: 1.5 * $gutter;
    padding-left: 0;
    color: $blue;

    &:before {
      content: none;
    }
  }

  .description {
    color: $blue;
  }
}
