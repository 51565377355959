$news-margin-tablet: 100px;
$news-margin-desktop: 120px;

.news {
  .block {
    &:before {
      @include tablet {
        background: #f7f7fb;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: $news-margin-tablet;
      }

      @include desktop {
        width: $news-margin-desktop;
      }
    }
  }

  .block__title .columns,
  .page-content {
    @include tablet {
      margin-left: $news-margin-tablet;
    }

    @include desktop {
      margin-left: calc(#{$news-margin-desktop} + #{$outer-hpadding-desktop * 0.5});
      margin-right: $outer-hpadding-desktop * 0.5;
    }
  }

  .page-content {
    &__quote,
    &__related-news,
    &__share {
      @include desktop {
        margin-left: $outer-hpadding-desktop * -1;
        padding-left: calc(#{$news-margin-desktop} + (#{$outer-hpadding-tablet}));
      }
    }

    .tag {
      @include desktop {
        margin-left: $gutter * -4;
      }
    }
  }
}
