.block__news {
  .columns + .columns {
    margin-top: $gutter;
  }

  .column {
    padding-bottom: 0;

    &:nth-of-type(3) {
      @include tablet-only {
        display: none;
      }
    }
  }

  .card {
    + .content-box {
      padding-top: $gutter * 0.5;
    }
  }

  a {
    color: $blue;

    &.tag {
      color: $dark-grey;

      &:active,
      &:focus,
      &:hover {
        color: $blue;
      }
    }
  }

  &.no-padding {
    @include tablet {
      padding: 0;
    }
  }
}

$news-margin-tablet: 100px;
$news-margin-desktop: 106px;

.is-related-news {
  margin-bottom: 2rem;

  @include tablet {
    margin-left: $news-margin-tablet;
  }

  @include desktop {
    margin-left: $news-margin-desktop;
  }
}