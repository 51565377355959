.search-form__input-control {
  &::before {
    color: $blue;
    content: "\f002";
    display: inline-block;
    flex: 0 0 1.5em;
    font-family: "Font Awesome 5 Pro";
    font-size: 1.5rem;
    font-weight: 600;
    align-self: center;
  }
}

.search-form__input-control {
  display: flex;

  .search-form__input {
    background: none;
    background-image: none;
    border: none;
    border-radius: unset;
    box-shadow: none;
    color: $blue;
    font-family: $font-primary-semibold;
    font-size: 1.2em;
    line-height: 1.15em;
    padding: 0;
    width: 100%;

    &:focus {
      border: none;
      box-shadow: none;
    }

    &::placeholder {
      color: $light-grey;
    }

    @include mobile-landscape {
      font-size: 1.5em;
    }

    @include tablet {
      font-size: 2.3125em;
    }
  }
}

.search-form__btn-submit {
  white-space: nowrap;
}

.search-form__btn-clear {
  color: $blue;
  cursor: pointer;
  font-size: 1.5em;
  align-self: center;
  transition: opacity ease-in $transition-fast;

  &.is-invisible {
    opacity: 0;
  }
}
