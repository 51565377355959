.items-by-topics__outer {
  margin: 0 -3.75em -3em;
  position: relative;
  &:before {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    display: block;
    @include bg-blue;
  }
  .items-by-topics__inner {
    margin: 3em 3.75em;
  }

  color: $blue;
  padding: 3.375em $outer-hpadding-mobile;

  @include mobile-landscape {
    padding-left: $outer-hpadding-mobile-landscape;
    padding-right: $outer-hpadding-mobile-landscape;
  }

  @include tablet {
    padding-left: 0;
    padding-right: 0;
  }
  .columns {
    flex-wrap: wrap;
  }
  .is-vertical {
    .column {
      &:first-of-type {
        margin-bottom: $gutter;
      }

      .button {
        display: block;
        max-width: $mobile-small-max;

        @include tablet {
          margin-left: auto;
        }
      }
    }
  }
  .columns__is-horizontal {
    .column {
      padding: 0 0.3em;

      .cta-item {
        display: inline-block;
        min-width: max-content;
      }
    }
  }

  .items-by-topics__content__title {
    color: white;
    padding: 0;
    margin-bottom: 16px;
  }
  .items-by-topics__content__link {
    color: white;
    text-decoration: underline;
  }
}
