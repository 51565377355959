.footer {
  background-position: bottom;
  line-height: 1.56;

  &,
  a {
    color: #fff;
  }

  &__title {
    font-size: 1.5em;
    margin: 1em 0;
  }

  p span {
    margin-right: 0.3em;
  }
}
