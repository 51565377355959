// DIVICE SIZES

$mobile-small-max: 320px;
$mobile-landscape: 414px;
$tablet: 768px;
$tablet-landscape: 1024px;
$desktop: 1280px;
$widescreen: 1600px;

$breakpointToFlexRow: $tablet;

// BASIC QUERIES
@mixin mobile-landscape {
  @media only screen and  (min-width: #{$mobile-landscape}) {
    @content;
  }
}
@mixin tablet {
  @media only screen and  (min-width: #{$tablet}) {
    @content;
  }
}
@mixin tablet-landscape {
  @media only screen and  (min-width: #{$tablet-landscape}) {
    @content;
  }
}
@mixin desktop {
  @media only screen and (min-width: #{$desktop}) {
    @content;
  }
}

// DEVICE ONLY QUERIES
@mixin mobile-only {
  @media only screen and (max-width: #{$tablet - 1}) {
    @content;
  }
}
@mixin tablet-only {
  @media only screen and (min-width: #{$tablet}) and (max-width: #{$desktop - 1}) {
    @content;
  }
}
@mixin desktop-only {
  @media only screen and (min-width: #{$desktop}) and (max-width: #{$widescreen - 1}) {
    @content;
  }
}
@mixin widescreen-only {
  @media only screen and (min-width: #{$widescreen}) {
    @content;
  }
}

// UNTIL SIZE QUERIES
@mixin until-mobile-landscape {
  @media only screen and (max-width: #{$mobile-landscape - 1}) {
    @content;
  }
}
@mixin until-tablet {
  @media only screen and (max-width: #{$tablet - 1}) {
    @content;
  }
}
@mixin until-tablet-landscape {
  @media only screen and (max-width: #{$tablet-landscape - 1}) {
    @content;
  }
}
@mixin until-desktop {
  @media only screen and (max-width: #{$desktop - 1}) {
    @content;
  }
}

// CUSTOM QUERIES
@mixin mq($width1, $width2: 0) {
  @if $width2 > 0 {
    @media only screen and (min-width: #{$width1} and (max-width: #{$width2}) ) {
      @content;
    }
  } @else {
    @media only screen and (min-width: #{$width1}) {
      @content;
    }
  }
}
