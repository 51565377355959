.yah__block {
  position: relative;
}

.yah__container {
  background: $dark-yellow;
  bottom: 0;
  color: $grey;
  left: 0;
  padding: 2em $outer-hpadding-mobile;

  a {
    color: $grey;
  }

  @include mobile-landscape {
    padding-left: $outer-hpadding-mobile-landscape;
    padding-right: $outer-hpadding-mobile-landscape;
  }

  @include tablet {
    // tablet blocks have container margin + outer-padding
    padding-left: $gutter + $outer-hpadding-tablet;
    padding-right: $gutter + $outer-hpadding-tablet;
  }

  @include tablet-landscape {
    display: inline-block;
    padding-left: 2em;
    padding-right: 2em;
  }
}

.yah__label {
  color: #000;
  padding-right: $gutter;
}

.yah__label,
.breadcrumb__container {
  font-size: $font-size-small;

  @include mobile-landscape {
    font-size: 1em;
  }
}

//Toolbox specifick
.toolbox-page {
  .yah__container {
    background: $white;
    color: $blue;

    @include mobile-only {
      border-top: 1px solid $light-blue;
    }
  }

  .yah__label {
    font-weight: 600;
    color: $blue;
  }
}
