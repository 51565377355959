.page-content {
  &__paragraphs {
    .column {
      &.is-full-width {
        max-width: 836px;
      }
      &.is-news-item {
        max-width: 400px;
      }
      &.is-half {
        &:first-of-type {
          @include tablet {
            padding-right: $block-spacing;
          }
        }

        &:last-of-type {
          @include tablet {
            padding-left: $block-spacing;
          }
        }
      }
    }
  }
}
