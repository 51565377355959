.wysiwyg {
  > *:not(:last-child) {
    margin-bottom: 2 * $gutter;
  }

  em {
    font-family: $font-primary-italic;
  }

  strong {
    font-family: $font-primary-bold;
    font-weight: normal;
  }

  a {
    text-decoration: underline;

    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  ol,
  ul {
    padding-left: 1.5 * $gutter;
  }

  ul {
    list-style: disc;
  }

  figure,
  img,
  iframe {
    margin: 0;
    max-width: 522px;
    width: 100% !important; // override Craft inline style
  }

  img {
    height: auto;
  }
}
