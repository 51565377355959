.section,
.container {
  display: block;
  margin: 0 auto;
  max-width: $content-max-width-desktop;
  width: 100%;

  @include tablet {
    padding: $gutter;
  }
}

.container--is-wide,
.container.is-wide {
  max-width: $content-max-outer-width;
}
