.navigation-toolbox {
  color: #fff;
  display: block;
  overflow: hidden;
}

.navigation-toolbox__body {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding: $gutter 0;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 3.5em;
    height: calc(100% - 7em);
    width: 1px;
    opacity: 0.3;
    background: $lighter-grey;
  }

  &:before {
    left: calc((100% + 2.5em) / 3);
  }

  &:after {
    right: calc((100% + 2.5em) / 3);
  }
}

.navigation-toolbox-item {
  margin: 0 0 4em 0;
  max-width: calc(100% / 3 - 32px);
  color: #fff;

  &:nth-child(n + 4) {
    margin-bottom: 0;
  }

  &:hover {
    text-decoration: none;
    .title {
      text-decoration: underline;
    }
  }

  .title {
    margin: 0 0 1em;
    font-size: 1.5em;
    @include arrow-left;

    &::before {
      padding-right: 0.25em;
    }
  }

  .description {
    font-size: 0.875em;
  }
}
