.tag,
.block h1.tag {
  color: $dark-grey;
  display: inline-block;
  font-family: $font-primary;
  font-size: $font-size-small;
  line-height: 2em;
  padding-left: 1.25em;
  position: relative;

  &::before {
    background: $gradient-orange;
    content: " ";
    height: 1.75em;
    left: 0;
    position: absolute;
    top: calc(50% - (1.75em / 2));
    width: 5px;
  }
}

.has-text-right {
  > .tag {
    padding-left: 0;
    padding-right: 1.5em;

    &::before {
      left: auto;
      right: 0;
    }
  }
}

.tag-link {
  color: $dark-grey;
  text-decoration: underline;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
}
