.assets-list {
  align-items: center;
  display: flex;

  &.full-width {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  img {
    max-height: 60px;
    max-width: 168px;
  }

  .assets-list__item {
    padding: $gutter 2 * $gutter;
  }

  &:not(.full-width) {
    .assets-list__item {
      padding: 0 $gutter * 0.5;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}
