.freeform-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 (-$gutter);

  @include tablet {
    flex-direction: row;
  }
}

.freeform-row .freeform-column {
  flex: 1 0;
  margin: 0 $gutter;
  padding: $gutter 0;
  box-sizing: border-box;
}

.freeform-row .freeform-column label,
.freeform-row .freeform-column .freeform-label,
.freeform-row .freeform-column > p {
  color: rgba($grey, 0.8);
  font-size: 1.2em;
}

.freeform-row .freeform-column label,
.freeform-row .freeform-column .freeform-label {
  display: block;
  margin-bottom: $gutter;
  padding: $gutter 0;
  position: relative;

  &:empty {
    display: none;
  }

  &.freeform-label-checkbox,
  &.freeform-label-input,
  &:not([class]) {
    color: #939393;
    font-size: 0.9em;
    margin-bottom: 0;
    padding-left: 3 * $gutter;
  }
}

.freeform-row .freeform-column .freeform-label {
  &.freeform-errors {
    color: $red;
  }
}

.freeform-row .freeform-column .freeform-label.freeform-required:after {
  content: "*";
  margin-left: $gutter * 0.5;
  color: $red;
}

.freeform-row .freeform-column .freeform-input:not(.button) {
  width: 100%;
  display: block;
}

.freeform-row .freeform-column .freeform-input[type="text"],
.freeform-row .freeform-column .freeform-input[type="email"],
.freeform-row .freeform-column .freeform-input[type="number"] {
  &.freeform-errors {
    border-color: $red;
  }
}

.freeform-row .freeform-column .freeform-input[type="checkbox"],
.freeform-row .freeform-column .freeform-input[type="radio"] {
  margin-right: $gutter * 0.5;
  position: absolute;
  top: calc(50% - 10px);
  left: 0;
}

.freeform-row .freeform-column {
  p {
    font-size: 1em;

    &.freeform-errors {
      color: $red;
    }

    &.freeform-success {
      color: $blue;
    }

    &.alert {
      border-radius: 5px;
      color: #fff;
      padding: $gutter $gutter * 2;

      &.freeform-errors {
        background: $red;
      }

      &.freeform-success {
        background: $blue;
      }
    }
  }
}

.freeform-row .freeform-column .freeform-instructions {
  margin: 0 0 ($gutter * 0.5);
  font-size: 0.9em;
  color: #aba7a7;
}

#__lpform_freeform_form_handle {
  display: none;
}
