.block {
  &__faq {
    h4 {
      color: $blue;
      font-size: 1.3em;
      margin-bottom: $gutter;
    }

    .faq-item {
      &:nth-of-type(odd) {
        @include tablet {
          padding-right: $block-spacing;
        }
      }

      &:nth-of-type(even) {
        @include tablet {
          padding-left: $block-spacing;
        }
      }

      &--has-answer {
        padding-left: $gutter + ($arrow-left-width * 1.125);

        .faq-item__question {
          @include arrow-left;
          color: $dark-grey;
          display: block;
          font-family: $font-primary-medium;

          &:hover {
            cursor: pointer;
          }

          &::before {
            margin-left: -$arrow-left-width;
          }
        }
      }

      input[type="checkbox"] {
        display: none;

        &:disabled + label {
          pointer-events: none;
        }

        &:checked + label {
          &::before {
            transform: rotate(0deg);
          }

          + .faq-item__answer {
            max-height: 100vh;
          }
        }
      }

      .faq-item__question {
        transition: max-height 0.25s ease-out;
      }

      .faq-item__answer {
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.2s ease;
      }
    }
  }
}
