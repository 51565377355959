.page-content {
  &__quote {
    border-top: 2px solid rgba($lighter-grey, 0.7);
    display: inline-block;
    padding-top: 3 * $gutter;

    @include tablet {
      padding: 3 * $gutter 4em 0 0;
    }

    h3 {
      color: $blue;
      font-family: $font-primary-semibold;
      font-size: 1.4em;
      line-height: 1.2em;

      @include mobile-landscape {
        font-size: 1.6em;
      }

      @include tablet {
        font-size: 2.3125em; // ie fallback
        font-size: clamp(1.7em, 2.3125vw, 2.3125em);
      }
    }
  }
}
