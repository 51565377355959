.item-tags__details-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 16px;
  @include tablet {
    flex-direction: row;
    margin-bottom: 16px;
  }
  .item-tags__detail {
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 16px 8px 0;
    color: rgba($dark-grey, 0.75);

    svg {
      color: $grey;
      width: 1em;
      margin-right: 8px;
    }
    span {
      display: block;
      margin-top: 4px;
      white-space: pre-wrap;
    }
  }
}

.item-tags__main {
  color: $blue;
  .item-tags__title {
    font-size: 21px;
    color: $blue;
  }
  .toolbox__list-item__description {
    font-size: 14px;
    color: $blue;
  }
  .toolbox__list-item__read-more {
    text-decoration: underline;
    color: $blue;
    font-size: 14px;
  }
}
