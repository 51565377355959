.related-entries-block__outer {
  .related-entries-block__head-title {
    margin: 0 0 2em;
    font-weight: bold;
  }
}

.related-entries-block__group {
  margin-bottom: 16px;
  &:nth-last-of-type() {
    margin-bottom: 0;
  }
  .related-entries-block__title {
    font-weight: bold;
    font-size: 12px;
    color: rgba($dark-grey, 0.75);
  }
  .related-entries-block__list {
    display: flex;
    flex-wrap: wrap;
    .related-entries-block__item {
      padding: 4px 4px 4px 0;
      margin: 0 8px 0 0;
      display: block;
      list-style: circle;
      font-size: 12px;
      color: $blue;
    }
  }
}
