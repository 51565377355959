.toolbox-search {
  background-position: left -42px;
  background-size: 100vw auto;
  padding: 1.125em;

  @include tablet {
    padding: 48px 16px;
    background-position: left -42px;
    background-size: 100vw auto;
  }

  form {
    position: relative;
  }

  .search-form__input {
    margin: 0 0 48px;
    position: relative;
    width: 100%;
    background: #fff;

    @include mobile-only {
      margin: 0 0 24px;
    }
  }

  p {
    margin: 0 0 12px 1em;
    min-width: 100%;
    color: #fff;

    @include mobile-only {
      display: none;
    }
  }

  .filters {
    display: flex;
    flex-direction: column;
  }

  .select-fields {
    display: flex;
    align-items: center;

    @include mobile-only {
      position: relative;
      overflow-x: scroll;
      margin: 0 -1.125em;

      &:after {
        content: "";
        display: block;
        min-width: 16px;
        height: 16px;
      }
    }

    @include tablet {
      flex-direction: row;
      flex-wrap: wrap;
    }

    p {
      display: flex;
      margin: 0 8px 0 1.125em;
      min-width: auto;
      white-space: nowrap;

      @include tablet {
        display: none;
      }
    }
  }

  select {
    height: 42px;
    margin: 0 0.5em 0 0;
    padding: 0 2em 0 1em;
    max-width: 100%;
    appearance: none;
    background: none;
    background-image: url("/assets/icons/chevron-down-solid-white.svg");
    background-repeat: no-repeat;
    background-position: right 1em top 50%;
    background-size: 0.5em auto;
    border: none;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2);

    @include mobile-only {
      margin: 0 0 0 8px;
      width: 175px;

      white-space: pre;
      text-overflow: ellipsis;

      &:last-child {
        margin-right: 4px;
      }
    }

    @include tablet {
      max-width: 25%;
    }

    &::-ms-expand {
      display: none;
    }
  }

  .search-form__btn-clear {
    position: absolute;
    top: 16px;
    right: 24px;
    background: none;
  }
}
