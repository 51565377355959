// UTILITY CLASSES
.is-hidden {
  display: none !important;
}

.has-text-no-wrap {
  white-space: nowrap;
}

.has-text-centered {
  text-align: center;
}

.has-text-justified {
  text-align: justify;
}

.has-text-left {
  text-align: left;
}

.has-text-right {
  text-align: right;
}

.is-full-width {
  width: 100%;
}

.is-full-height {
  height: 100%;
}

.lighten {
  opacity: 0.73;
}

.color-yellow {
  color: $yellow;
}

.pull-down-right {
  bottom: 0;
  position: absolute;
  right: 0;
}

.object-fit {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.no-padding {
  padding: 0 !important;
}

.with-background {
  @include bg-blue;
}

.folds-vertical {
  transform-origin: center center;
  transition: transform ease-in $transition-fast;

  &.is-collapsed {
    transform: scaleY(0);
  }
}

// BREAKPOINTS
@include mobile-only {
  .is-hidden-mobile {
    display: none !important;
  }
}

@include until-mobile-landscape {
  // @media screen and (max-  idth: $mobile-portrait) {
  .is-hidden-mobile-portrait {
    display: none !important;
  }

  .has-text-left-mobile-portrait {
    text-align: left;
  }

  .has-text-right-mobile-portrait {
    text-align: right;
  }

  .has-text-centered-mobile-portrait {
    text-align: center;
  }
}

@include mobile-landscape {
  .is-hidden-mobile-landscape {
    display: none !important;
  }

  .has-text-left-mobile-landscape {
    text-align: left;
  }

  .has-text-right-mobile-landscape {
    text-align: right;
  }

  .has-text-centered-mobile-landscape {
    text-align: center;
  }
}

@include tablet {
  .is-hidden-tablet {
    display: none !important;
  }

  .has-text-centered-tablet {
    text-align: center;
  }

  .has-text-left-tablet {
    text-align: left;
  }

  .has-text-right-tablet {
    text-align: right;
  }
}

@include tablet-landscape {
  .has-text-right-tablet-landscape {
    text-align: right;
  }
}

@include desktop {
  .has-text-centered-desktop {
    text-align: center;
  }

  .has-text-left-desktop {
    text-align: left;
  }

  .has-text-right-desktop {
    text-align: right;
  }
}

$space-medium: $gutter;
$space-big: $gutter * 2;

.u-margin-top-medium {
  margin-top: $space-medium;
}

.u-margin-top-big {
  margin-top: $space-big;
}

.u-margin-bottom-medium {
  margin-bottom: $space-medium;
}

.u-margin-bottom-big {
  margin-bottom: $space-big;
}

.u-grid {
  display: grid;
  grid-auto-flow: row;
  row-gap: $gutter * 2;
  column-gap: $gutter * 2; 
  
  &--tablet-3-columns {
    @include tablet {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &--desktop-4-columns {
    @include desktop {
      grid-template-columns: repeat(4, 1fr);
    }    
  }
}

.u-color-orange {
  color: $orange;
}
