// Grid layout
.toolbox-item-list,
.list-item {
  width: 100%;
}

.list-item {
  width: 100%;
  padding: 0 0 1.5em;
  margin-bottom: 0;
  background: $white;
  border-bottom: 10px solid $light-blue;
  &:last-of-type {
    border: none;
  }

  @include tablet {
    padding: 1.25em 1.5em 0;
    border: none;
  }

  @include tablet {
    &.odd {
      background: rgba(224, 222, 238, 0.25);
    }
  }
}

.list-item__inner {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  width: 100%;
  min-height: 170px;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  @include tablet {
    flex-direction: row;
    justify-content: space-between;
  }
}

// Basic styling
.list-item__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1.125em;
  @include tablet {
    padding: 0;
    width: calc((100% / 6) * 4);
    flex-direction: row;
    flex-wrap: wrap;
  }

  h2.title {
    margin: 0 0 0.2em;
    font-size: 1.5em;
    $font-family: $font-primary-medium;
  }

  .description {
    color: rgba($dark-grey, 0.75);
  }

  .read-more {
    text-decoration: underline;
  }
}

.list-item__details-container {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-right: 16px;
  @include desktop {
    width: 140px;
  }
}
.list-item__content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-right: 16px;
  @include desktop {
    width: calc(100% - 140px);
    &.full-layout {
      width: 100%;
    }
  }
}
.list-item__detail-item {
  margin: 0 0 0.75em;
  @include tablet {
    margin: 0 16px 0 0;
  }
}

.list-item__text__inner {
  display: flex;
  flex-wrap: wrap;
}

.list-item__image {
  padding-top: 50%;
  width: 100%;
  margin-bottom: 16px;
  @include tablet {
    padding-top: 0;
    max-width: 250px;
    width: calc((100% / 6) * 2);
  }
}
