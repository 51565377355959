.toolbox-pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1.125em;
  border-top: 10px solid $light-blue;

  @include tablet {
    border: none;
    padding: 2.75em;
  }

  .title {
    margin: 0 auto 0 0;
    font-size: 13px;
    color: $light-grey;

    @include mobile-only {
      margin: 0 0 1.5em;
      min-width: 100%;
    }
  }

  [class*="link"] {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $blue;
    font-weight: bold;
    flex: 0 0 28px;
    width: 28px;
    height: 28px;
  }

  .link--current {
    color: $white;
    background: $blue;
    border-radius: 100%;
  }
}
