// ========
// FLEX BOX
// ========
@use "sass:math";

.columns {
  display: block;
  margin-left: -$gutter;
  margin-right: -$gutter;
  margin-top: -$gutter;

  > .columns {
    margin: 0;
  }
}

.columns__is-horizontal,
.columns.is-horizontal {
  display: flex;
  flex-direction: row;
}

@include mq($breakpointToFlexRow) {
  .columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.columns--is-vertical,
.columns.is-vertical {
  flex-direction: column;
}

.columns:not(:last-child) {
  margin-bottom: $gutter;
}

.columns--is-multiline,
.columns.is-multiline {
  flex-wrap: wrap;
}

.columns--is-centered,
.columns.is-centered {
  justify-content: center;
}

.columns--is-right,
.columns.is-right {
  justify-content: flex-end;
}

.columns--is-vcentered,
.columns.is-vcentered {
  align-items: center;
}

.columns--is-gapless,
.columns.is-gapless {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}

.columns--is-gapless > .column,
.is-gapless > .column {
  margin: 0;
  padding: 0 !important;
}

.columns--is-gapless:not(:last-child) {
  margin-bottom: 0;
}

.columns--is-gapless:last-child {
  margin-bottom: $gutter;
}

// =========
// FLEX ITEM
// =========
.column {
  flex: 1;
  padding: $gutter;
}

.column--is-narrow,
.column.is-narrow {
  flex: none;
}

.column--is-gapless,
.column.is-gapless {
  margin: 0;
  padding: 0 !important;
}

.column--is-full-width,
.column.is-full-width {
  flex: 0 0 100%;
}

.column--is-vcentered,
.column.is-vcentered {
  align-self: center;
}

.column--is-vbottom,
.column.is-vbottom {
  align-self: flex-end;
}

@include mq($breakpointToFlexRow) {
  .column--is-half,
  .column.is-half {
    flex: none;
    width: 50%;
  }

  .column--is-third,
  .column.is-third {
    flex: none;
    width: 33.33%;
  }

  .column--is-quarter,
  .column.is-quarter {
    flex: none;
    width: 25%;
  }
}

@for $i from 1 through $maxcols {
  .columns__is-horizontal,
  .columns.is-horizontal {
    .column--is-#{$i}\/#{$maxcols},
    .column.is-#{$i}\/#{$maxcols} {
      flex: none;
      width: (math.div(100, $maxcols) * $i) * 1%;
    }
  }

  @include mq($breakpointToFlexRow) {
    .column--is-#{$i}\/#{$maxcols},
    .column.is-#{$i}\/#{$maxcols} {
      flex: none;
      width: (math.div(100, $maxcols) * $i) * 1%;
    }
  }

  @include mobile-landscape {
    .column--is-#{$i}\/#{$maxcols}-mobile-landscape,
    .column.is-#{$i}\/#{$maxcols}-mobile-landscape {
      flex: none;
      width: (math.div(100, $maxcols) * $i) * 1%;
    }
  }

  @include tablet {
    .column--is-#{$i}\/#{$maxcols}-tablet,
    .column.is-#{$i}\/#{$maxcols}-tablet {
      flex: none;
      width: (math.div(100, $maxcols) * $i) * 1%;
    }
  }

  @include tablet-landscape {
    .column--is-#{$i}\/#{$maxcols}-tablet-landscape,
    .column.is-#{$i}\/#{$maxcols}-tablet-landscape {
      flex: none;
      width: (math.div(100, $maxcols) * $i) * 1%;
    }
  }

  @include desktop {
    .column--is-#{$i}\/#{$maxcols}-desktop,
    .column.is-#{$i}\/#{$maxcols}-desktop {
      flex: none;
      width: (math.div(100, $maxcols) * $i) * 1%;
    }
  }
}

// BREAKPOINTS
@include mobile-only {
  .columns--is-multiline-mobile-portrait,
  .columns.is-multiline-mobile-portrait {
    flex-wrap: wrap;
  }
}

@include mobile-landscape {
  .column--is-narrow-mobile-landscape,
  .column.is-narrow-mobile-landscape {
    flex-grow: 0;
    width: auto;
  }
}

@include tablet {
  .column--is-narrow-tablet,
  .column.is-narrow-tablet {
    flex-grow: 0;
    width: auto;
  }

  .column--is-full-width-tablet,
  .column.is-full-width-tablet {
    flex: 0 0 100%;
  }

  .columns--is-vcentered-tablet,
  .columns.is-vcentered-tablet {
    align-items: center;
  }
}

@include tablet-landscape {
  .columns--is-singleline-tablet-landscape,
  .columns.is-singleline-tablet-landscape {
    flex-wrap: nowrap;
  }

  .column--is-narrow-tablet-landscape,
  .column.is-narrow-tablet-landscape {
    flex-grow: 0;
    width: auto;
  }

  .column--is-full-width-tablet-landscape,
  .column.is-full-width-tablet-landscape {
    flex: 0 0 100%;
  }

  .columns--is-vcentered-tablet-landscape,
  .columns.is-vcentered-tablet-landscape {
    align-items: center;
  }
}

@include until-desktop {
  .columns--is-vertical-desktop,
  .columns.is-vertical-desktop {
    flex-direction: column;
  }
}

@include desktop {
  .column--is-narrow-desktop,
  .column.is-narrow-desktop {
    flex-grow: 0;
    width: auto;
  }

  .columns--is-centered-desktop,
  .columns.is-centered-desktop {
    justify-content: center;
  }
}
