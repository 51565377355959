.block {
  margin-bottom: $block-spacing * 0.5;
  padding: $gutter $outer-hpadding-mobile;
  position: relative;
  &.no-padding {
    padding: 0;
  }

  &:not(.with-background) {
    background: #fff;
  }

  &.columns {
    margin-left: 0;
    margin-right: 0;
    padding-left: $gutter;
    padding-right: $gutter;
  }

  @include mobile-landscape {
    padding-left: $outer-hpadding-mobile-landscape;
    padding-right: $outer-hpadding-mobile-landscape;

    &.columns {
      padding-left: $outer-hpadding-mobile-landscape - $gutter;
      padding-right: $outer-hpadding-mobile-landscape - $gutter;
    }
  }

  @include tablet {
    margin-top: $block-spacing * 0.5;
    padding: (3 * $gutter) ($outer-hpadding-tablet);

    &.columns {
      padding-left: $outer-hpadding-tablet - $gutter;
      padding-right: $outer-hpadding-tablet - $gutter;
    }
  }

  @include desktop {
    margin-bottom: $block-spacing;
    margin-top: $block-spacing;
    padding: 4 * $gutter $outer-hpadding-desktop;

    &.columns {
      padding-left: $outer-hpadding-desktop - $gutter;
      padding-right: $outer-hpadding-desktop - $gutter;
    }
  }

  h1 {
    font-size: 1.8em;

    @include mobile-landscape {
      font-size: 2.3125em;
    }
  }

  h2 {
    font-size: 1.5em;

    @include mobile-landscape {
      font-size: 1.8em;
    }
  }

  h1,
  h2 {
    color: $blue;
    font-family: $font-primary-semibold;
    line-height: 1.15em;

    + .wysiwyg,
    + .columns {
      padding-top: 2 * $gutter;
    }
  }

  h4 {
    font-family: $font-primary-semibold;
    font-size: 1.2em;
  }

  p,
  li {
    color: rgba($dark-grey, 0.75);
    line-height: 1.88;
  }

  a.button--primary {
    margin-top: $gutter;
  }

  img {
    display: block;
  }

  &.with-background {
    color: #fff;

    h1,
    h2,
    p,
    li,
    span,
    .tag {
      color: #fff;
    }

    h1 {
      font-family: $font-primary;
    }

    p {
      opacity: 0.7;
    }

    a:not(.button--primary) {
      color: $yellow;
    }
  }
}
