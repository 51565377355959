// General layout
.toolbox-intro__outer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  margin-bottom: 1.375em;
  @include tablet {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 2.75em;
  }
  .toolbox-intro__main-content {
    background: white;
    width: 100%;
    @include tablet {
      padding-right: 2.75em;
      width: calc((100% / 3) * 2);
    }
  }
  .toolbox-intro__sidebar {
    padding-top: 20px;
    background: $blue-pink;
    width: 100%;
    margin-top: 1.375em;
    @include tablet {
      margin-top: 0;
      padding-top: 0;
      padding-left: 2.75em;
      width: calc((100% / 3) * 1);
    }
  }
  .toolbox-intro__main-content,
  .toolbox-intro__sidebar {
    padding-left: 1.125em;
    padding-right: 1.125em;
    @include tablet {
      padding: 2.25em 3em;
    }
    @include desktop {
      padding: 3em 3.75em;
    }
    p {
      line-height: 1.88;
      color: rgba($dark-grey, 0.75);
    }
  }
}

// Main content
.toolbox-intro__main-content {
  .toolbox-intro__details,
  .toolbox-intro__publication-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: $blue;
    .toolbox-intro__detail-inline {
      display: inline-block;
      &:nth-of-type(n + 2) {
        margin-left: 16px;
        padding-left: 16px;
        position: relative;
        &:before {
          content: "|";
          display: block;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
  .toolbox-intro__details {
    font-size: 12px;
    margin-bottom: 16px;
  }
  .toolbox-intro__publication-content {
    margin-top: 20px;
  }
  .toolbox-intro__main-content__title {
    color: $blue;
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 30px;
  }
  .toolbox-intro__main-content__content {
    color: $blue;
  }
}

// Sidebar content
.toolbox-intro__sidebar {
  color: rgba($dark-grey, 0.75);
  font-size: 12px;
  > div {
    margin-bottom: 20px;
  }

  a {
    text-decoration: underline;
    word-break: break-all;
  }

  .toolbox-intro__doi__group {
    margin-bottom: 16px;
    .toolbox-intro__doi__title {
      font-weight: bold;
      color: rgba($dark-grey, 0.75);
    }
  }
}
