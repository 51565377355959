input[type="text"],
input[type="email"],
input[type="number"],
textarea,
select {
  appearance: none;
  background: $light-blue;
  border: 1px solid $blue;
  border-radius: 2em;
  line-height: 1;
  outline: none;
  padding: 1em 2em;
  transition: all ease $transition-fast;

  &:active,
  &:focus {
    border: 1px solid $light-blue;
    box-shadow: 0 0 0 2px lighten($blue, 15%);
  }
}

select {
  appearance: none;
  background-image: url("/assets/icons/chevron-down-solid.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 1em top 50%, 0 0;
  background-size: 1em auto, 100%;
  padding: 1.25em 2em 1em 2em;

  &::-ms-expand {
    display: none;
  }
}

input[type="checkbox"],
input[type="radio"] {
  appearance: none;
  display: flex;
  outline: none;
  position: relative;

  &:before {
    background-color: $light-blue;
    border: 1px solid $blue;
    border-radius: 3px;
    content: "";
    display: inline-block;
    height: 16px;
    margin-bottom: -4px;
    margin-right: 0.5 * $gutter;
    width: 16px;
  }

  &:after {
    content: "";
    opacity: 0;
    position: absolute;
    transition: opacity ease $transition-fast;
  }

  &:checked {
    &:after {
      opacity: 1;
    }
  }
}

input[type="checkbox"] {
  &:after {
    border: solid $blue;
    border-width: 0 3px 3px 0;
    height: 7px;
    left: 6px;
    top: 3px;
    transform: rotate(45deg);
    width: 4px;
  }
}

input[type="radio"] {
  &:before {
    border-radius: 50%;
  }

  &:after {
    background: $blue;
    border-radius: 50%;
    height: 6px;
    left: 6px;
    top: 6px;
    width: 6px;
  }
}
