// BTN BASE STYLING
.button {
  background: $gradient-orange-h;
  cursor: pointer;
  text-decoration: none;

  &--primary {
    @include arrow-left;
    border-radius: 1.5em;
    color: $dark-grey;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    padding: 0.575em 1.375em;
    text-overflow: ellipsis;
  }

  &--rect {
    align-items: center;
    color: $blue;
    display: flex;
    font-size: 1.125em;
    padding: 1.5em 2em;
    white-space: nowrap;

    img,
    i {
      margin-right: $gutter;
    }
  }

  &--is-text {
    @include arrow-left;
    background: none;
    color: $dark-grey;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }
}
