.block-news-overview, .block-webinars-overview {
  position: relative;
  overflow: hidden;

  @include mobile-only {
    margin-bottom: 0 !important;
  }

  &__title-container {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 2em $double-gutter;

    @include tablet {
      padding: 2em 3em;
    }

    @include mobile-only {
      padding: $gutter $double-gutter;
    }

    @media only screen and (min-width: 425px) and (max-width: #{$tablet - 1}) {
      padding: $double-gutter $tripple-gutter $double-gutter $tripple-gutter;
    }

  }

  &__title {
    color: $white;
  }

  &__tag {
    margin-bottom: 1.5 * $gutter;
  }

  &__categories-background {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    @include tablet {
      width: 120%;
      transform: skewX(25deg);
    }
  }

  &__categories {
    height: 100%;
    padding: 1.125em $double-gutter;
    align-items: center;

    @include mobile-only {
      padding: $double-gutter $double-gutter 0 $double-gutter;
    }

    @media only screen and (min-width: 425px) and (max-width: #{$tablet - 1}) {
      padding: $double-gutter $tripple-gutter 0 $tripple-gutter;
    }

    @include tablet {
      padding: $tripple-gutter;
      transform: skewX(25deg);
    }
  }

  &__categories-list {
    list-style: none;
  }

  &__categories-item {
    min-height: 27px;
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 16px;
    padding: 0 14px;
    background: #e0deee;
    text-align: center;
    border-radius: 20px;

    @include tablet {
      transform: skewX(-25deg);
      margin-right: 8px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    a {
      font-size: 14px;
      font-weight: 500;
      color: #414e86 !important;
    }

    &.active, &:hover {
      background: $gradient-orange;

      a {
        color: #231f20 !important;
        text-decoration: none;
      }
    }
  }
}

