.toolbox-header {
  display: flex;
  flex-direction: column;
  background: #fff;
}

.toolbox-header__heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em 1em 1.5em;
  color: $blue;

  @include tablet {
    padding: 2em 24px 0;
  }
}

.toolbox-header__nav {
  display: flex;
  @include tablet {
    flex-wrap: wrap;
  }
  @include bg-blue;
  background-size: 100vw;
  background-position: left top;

  &:before {
    content: "";
    flex: 1;
    background: #fff;
  }

  .nav-link {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 2px 0 0;
    height: 42px;
    background: $blue-light;
    color: $blue;
    &.no-amount {
      .name {
        @include tablet {
          margin: 24px;
        }
      }
    }

    @include mobile-only {
      padding: 2px 8px 0;
      border-right: 4px solid #fff;

      &:last-child {
        border-right: none;
      }
    }

    @include tablet {
      width: auto;
      border-left: 4px solid #fff;
      &:first-child {
        border-left: 0px solid #fff;
      }
      &:last-child {
        border-right: 24px solid #fff;
      }

      span:last-child {
        margin-right: 24px;
      }
    }

    .name {
      white-space: nowrap;
      @include mobile-only {
        font-size: 13px;
      }
      @include tablet {
        width: 140px;
        margin: 0 0 0 24px;
        width: auto;
      }
    }

    .amount {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -2px 24px 0 8px;
      padding: 1px 0 0;
      flex: 0 0 28px;
      width: 28px;
      height: 28px;
      background: $blue;
      color: #fff;
      border-radius: 100px;

      @include mobile-only {
        display: none;
      }
    }

    &.active {
      background: none;
      color: #fff;

      .amount {
        background: #fff;
        color: $blue;
      }
    }
  }
}
