.navigation-main {
  color: #fff;
  display: block;
  overflow: hidden;
}

.navigation-main__body {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: $gutter 0;
}

.navigation-main__column {
  padding: 2 * $gutter 0;
}

.navigation-main__title {
  cursor: pointer;
  font-family: $font-primary;
  font-size: 1.5em;
  padding-bottom: $gutter;

  &::after {
    content: "\f054";
    position: absolute;
    right: 0;
    display: inline-block;
    font-family: "Font Awesome 5 Pro";
    font-size: 1em;
    font-style: normal;
    height: 1em;
    transform: rotate(180deg);
    transform-origin: 0.25em center;
    transition: transform ease-in-out $transition-fast;
    width: 1em;
  }
}

.navigation-main__item-list {
  transition: max-height ease-in-out $transition-fast;
  overflow: hidden;
}

.navigation-main .is-expanded {
  .navigation-main__title {
    &::after {
      transform: rotate(90deg);
    }
  }
}

.navigation-main__item {
  color: #fff;
  display: block;
  font-size: 1.125em;
  margin: 0;
  padding: $gutter 0;
  text-decoration: none;
}

// BREAKPOINTS
@include tablet {
  .navigation-main__column .navigation-main__item-list {
    position: relative;
    height: auto;
    max-height: none !important;
    visibility: visible;
  }

  .navigation-main__body {
    flex-direction: row;
    padding: 3.5em 0;
  }

  .navigation-main__column {
    padding: $gutter;
  }

  .navigation-main__title {
    pointer-events: none;
    font-size: 2.25em;
    padding-top: 0;

    &::after {
      content: none;
    }
  }

  .navigation-main__item-list {
    display: block;
  }

  .navigation-main__item {
    font-size: 1em;
    @include arrow-left;

    &::before {
      padding-right: 0.75em;
    }
  }
}

@include desktop {
  .navigation-main__column {
    border-left: 1px solid rgba($lighter-grey, 0.5);
    padding: 0 3 * $gutter;
  }
}
