.page-nav__back {
  display: flex;
  align-items: center;
  color: $blue;
  font-size: 12px;
  margin: 1.375em 0;
  padding-left: 1.125em;
  padding-right: 1.125em;
  text-decoration: underline;
  @include tablet {
    font-size: 16px;
    color: white;
    padding-left: 2.25em;
    padding-right: 2.25em;
    margin-top: 0;
    margin-bottom: 2.75em;
  }
  svg {
    width: 16px;
    margin-right: 8px;
  }
  p {
    margin-top: 4px;
  }
}

.toolbox-content__outer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.75em;
  background: white;
  @include tablet {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .toolbox-content__main-content {
    width: 100%;
    background: $white;
    @include tablet {
      padding-right: 2.75em;
      width: calc((100% / 3) * 2);
    }
  }
  .toolbox-content__sidebar__content {
    width: 100%;
    background: $blue-pink;
    @include tablet {
      padding-left: 2.75em;
      width: calc((100% / 3) * 1);
    }
  }

  .toolbox-content__main-content,
  .toolbox-content__sidebar__content {
    padding-left: 1.125em;
    padding-right: 1.125em;
    @include tablet {
      padding: 2.25em 3em;
    }
    @include desktop {
      padding: 3em 3.75em;
    }
  }

  .page-content:not(:last-of-type).page-content__title {
    margin-bottom: $block-spacing * 0.25;

    @include tablet-landscape {
      margin-bottom: $block-spacing * 0.5;
    }
  }
}

@include mobile-only {
  .publications__outer,
  .toolbox-page__outer {
    .block.no-padding {
      margin-bottom: 8px;
      border-bottom: 20px solid $light-blue;

      &:last-child {
        margin-bottom: 0;
      }

      &.toolbox-content__outer {
        padding-bottom: 2.75em;
      }
    }
  }
}

// Exceptions
.toolbox-content__outer {
  .page-content__thumbnails {
    ul.columns {
      .is-half {
        width: 100%;
      }
    }
  }
  .page-content__link-list {
    .page-content__link-list__list {
      ul {
        margin: -2.75em 0;
      }
      li {
        color: black;
        background: $gradient-orange-h;
        border-radius: 40px;
        margin-bottom: 4px;
        @include tablet {
          width: calc(50% - 8px);

          &:nth-of-type(odd) {
            margin-right: 8px;
          }
          &:nth-of-type(even) {
            margin-left: 8px;
          }
        }
        a {
          display: inline-block;
          width: calc(100% - 24px);
        }
      }
    }
  }

  .button--primary {
    display: inline-flex;
    align-items: center;
    color: $blue;

    a {
      color: $blue;
    }
  }
}
