.page-content {
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: $block-spacing * 0.5;

    @include tablet-landscape {
      margin-bottom: $block-spacing;
    }
  }

  h1,
  h2,
  h3,
  p {
    max-width: 836px;
    width: 100%;
  }

  p.pre-line {
    color: $dark-yellow;
    line-height: 1.4em;
    white-space: pre-line;

    span {
      color: $dark-grey;
      font-size: 0.9em;
    }
  }
}

//Toolbox specifick
.toolboxContent {
  .page-content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $blue;
    }

    a {
      color: $dark-grey;
    }
  }
}
.is-news-item {
  max-width: 720px;
}
