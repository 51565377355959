.content-box {
  margin: 0;
  padding: $gutter 0;
}

.content-box--h {
  padding: $gutter;
}

.content-box--h2x {
  padding: $gutter 2 * $gutter;
}

.content-box--v2x {
  padding: 2 * $gutter 0;
}
