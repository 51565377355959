.latest-items__outer {
  margin-bottom: 2.75em;
  &:nth-last-of-type() {
    margin-bottom: 0;
  }

  .latest-items__header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    @include tablet {
      flex-direction: row;
    }
  }

  .latest-items__title {
    font-weight: bold;
    & + .latest-items__link {
      margin-left: 16px;
    }
  }
}
