.items-list-container__outer {
  padding: 1.25em 1.125em;
  margin: 0;

  @include tablet {
    padding: 1.25em 1.5em 0;
    margin-bottom: 16px;
  }

  .items-list-container__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include tablet {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      border-bottom: 1px solid $light-grey;
      padding-bottom: 16px;
    }

    .items-list-container__title {
      color: $blue;
    }

    &.search {
      .items-list-container__title {
        margin: 4px 0 0;
        font-size: 13px;
        color: $light-grey;
      }
    }
  }

  .order-button {
    font-size: 13px;
    background: none;
    color: $blue;

    @include mobile-only {
      margin-top: 8px;
    }

    .fas {
      margin: 0 0 0 4px;
      font-size: 12px;
    }
  }
}
