$footer-padding: 3.75rem;

.footer-main {
  padding: $footer-padding $outer-hpadding-mobile;

  @include mobile-landscape {
    padding-left: $gutter + $outer-hpadding-mobile-landscape;
    padding-right: $gutter + $outer-hpadding-mobile-landscape;
  }

  @include tablet-landscape {
    padding-left: 0;
    padding-right: 0;
  }

  .logo {
    margin-bottom: $gutter;
  }

  > .column {
    &:first-of-type {
      @include tablet-landscape {
        border-right: 1px solid rgba($lighter-grey, 0.5);
        padding-right: $footer-padding;

        p {
          white-space: nowrap;
        }
      }
    }

    &:last-of-type {
      @include tablet-landscape {
        flex-grow: 3;
        padding: 0 $gutter 0 $footer-padding;
      }
    }

    .footer__nav-container {
      margin-left: -$footer-padding;
    }

    .footer__nav-container,
    .navigation-main,
    .navigation-main__body {
      height: 100%;
    }

    .footer__nav-container {
      display: none;
    }

    .navigation-main__body {
      padding: 0;
    }

    @include tablet-landscape {
      .footer__nav-container {
        display: block;
      }

      .navigation-main__title {
        font-size: 1.5em;
      }

      .navigation-main__item {
        font-size: $font-size-small;
      }

      .navigation-main__column {
        border-left: 1px solid rgba($lighter-grey, 0.5);
        padding: $gutter 1.25em;

        &:first-child {
          border-left: none;
        }
      }
    }
  }

  a.is-rounded {
    background: #fff;
    @include rounded(38px);
  }
}
