.topbar {
  //todo: Remove on live
  // display: none;

  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  display: flex;
  height: $topbar-height;
  justify-content: flex-start;
  width: 100%;

  &:after {
    content: "";
    height: 100%;
    width: 100%;
    background: $light-blue;
  }

  &__link {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 24px;
    font-size: 13px;
    background: $light-blue;
    color: $dark-grey;

    &--active {
      background: none;
      color: #fff;
    }
  }

  &__projects {
    display: flex;
  }

  &__languages {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    height: 100%;
    bottom: 0;
    align-items: center;
  }
  &__language-item {
    height: 13px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 24px;

    &:not(:last-of-type) {
      border-right: 1px solid black;
    }

    &--current {
      font-family: $font-primary-bold;
    }
  }
}

.navbar__container {
  top: 0;
}
