.page-content__link-list {
  .column {
    padding-top: $block-spacing * 0.5;
    padding-bottom: $block-spacing * 0.5;
  }

  .page-content__link-list__description {
    font-family: $font-primary-semibold;
  }

  .page-content__link-list__list ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 - $block-spacing;
    padding-bottom: $block-spacing;
    padding-top: $block-spacing;

    @include tablet-landscape {
      flex-direction: row;
    }

    li {
      @include arrow-left;
      padding: $gutter $block-spacing;

      @include mq($breakpointToFlexRow) {
        flex: none;
        width: 50%;
      }

      a {
        &::before {
          margin-left: -$arrow-left-width;
        }
      }
    }
  }
}
