.page-content {
  &__external-links {
    @include mobile-only {
      .columns {
        display: flex;
        flex-direction: column;
      }
    }

    .external-link {
      position: relative;
      padding: 1.5em 1em 1.5em 3em;
      color: $dark-grey;
      line-height: 1.4em;

      &:before {
        content: "\F061";
        position: absolute;
        top: 1.5em;
        left: 0.75em;

        display: block;
        font-family: "Font Awesome 5 Pro";
        font-size: 1.125em;
        transform: rotate(45deg);
      }
    }
  }
}
