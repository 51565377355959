$footer-hpadding-mobile: 2 * 0.75rem;
$footer-hpadding-mobile-landscape: 3 * 0.75rem;
$footer-hpadding-tablet: 5 * 0.75rem;

.footer-bar {
  background: #fff;
  color: $grey;
  overflow: hidden;

  @include tablet-landscape {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  > * {
    margin: (3 * $gutter) $footer-hpadding-mobile;

    @include mobile-landscape {
      margin-left: $footer-hpadding-mobile-landscape;
      margin-right: $footer-hpadding-mobile-landscape;
    }

    @include tablet {
      margin-left: $footer-hpadding-tablet;
      margin-right: $footer-hpadding-tablet;
    }

    @include tablet-landscape {
      margin: 0;
    }
  }

  a {
    color: $grey;
  }

  p {
    align-items: center;
    display: flex;

    @include until-tablet-landscape {
      margin-bottom: 2 * $gutter;
    }

    img {
      margin-right: 2 * $gutter;
    }
  }

  .logo {
    display: inline-block;
    height: 24px;
    fill: $dark-grey;
  }

  .button--rect {
    margin: 0;

    @include mobile-landscape {
      padding-left: $footer-hpadding-mobile-landscape;
      padding-right: $footer-hpadding-mobile-landscape;
    }

    @include tablet {
      padding-left: $footer-hpadding-tablet;
      padding-right: $footer-hpadding-tablet;
    }

    @include until-tablet-landscape {
      margin: 0;
    }
  }

  .assets-list {
    @include tablet-landscape {
      margin-left: auto;
      margin-right: 3 * $gutter;
    }
  }

  &__item-list {
    font-size: 0.85em;

    @include tablet-landscape {
      align-items: center;
      display: flex;
      margin-left: $gutter;
    }

    @include desktop {
      margin-left: 3 * $gutter;
    }
  }

  &__nav-item {
    @include tablet-landscape {
      display: inline-block;
      padding: 0 2 * $gutter;
      white-space: nowrap;
      margin: $gutter * 0.5 0;

      &:not(:last-child) {
        border-right: 1px solid rgba($grey, 0.36);
      }
    }
  }
}
