.carousel__container {
  display: flex;
  height: 100%;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.carousel__slide__container {
  display: inline-block;
  flex: none;
  height: 100%;
  width: 100%;
}

.carousel__slide {
  margin: 0 auto;
  max-width: $content-max-width-desktop;
  overflow: hidden;
  padding: $gutter;
  bottom: 0;
  padding-bottom: 2em;
  margin-top: 10em;
  &.has-video {
    margin-top: 7em;
  }

  @include tablet {
    position: relative;
    margin-top: 7em;
    bottom: auto;
    padding-bottom: 5em;
    &.has-video {
      padding-bottom: 2em;
    }
  }
  @include tablet-landscape {
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    bottom: auto;
    margin: 0 auto;
    padding-bottom: 0;
    &.has-video {
      top: 40%;
      margin-top: 0em;
    }
  }
  @include desktop {
    position: relative;
    &.has-video figure{
      margin-left: 3em;
    }
  }

  figure {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    @include tablet-landscape {
      justify-content: start;
    }
    & iframe {
      width: 80vw !important;
      aspect-ratio: 16/9;
      height: auto !important;
      @include tablet-landscape {
        height: 20em !important;
        width: auto !important;
      }
    }
  }
}

.carousel__text {
  white-space: normal;
}

.carousel__title {
  font-size: 1.5em;
  text-shadow: $shadow-grey;
  white-space: pre-wrap;

  @include mobile-landscape {
    font-size: 2.25em; // ie fallback
    font-size: clamp(1.5em, 3vw, 3em);
  }
}

.carousel__indicator__container {
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 2 * $gutter $gutter;
  position: absolute;
  width: 100%;

  @include tablet-landscape {
    flex-direction: column;
  }
}

.carousel__indicator {
  border: 0.1875em solid #fff;
  border-radius: 50%;
  box-shadow: $shadow-grey;
  cursor: pointer;
  display: inline-block;
  height: 1em;
  left: 0;
  margin: 0.125em;
  top: 0;
  width: 1em;

  &.is-active::before {
    background: #fff;
    border-radius: 50%;
    content: "";
    display: inline-block;
    height: 0.5em;
    left: 0.0625em;
    position: absolute;
    top: 0.0625em;
    width: 0.5em;
  }
}

.carousel__control__container {
  bottom: 0;
  padding: $gutter;
  position: absolute;
  text-shadow: $shadow-grey;
  width: 100%;
}

.carousel__control-prev,
.carousel__control-next {
  cursor: pointer;
  display: inline-block;
  font-family: "Font Awesome 5 Pro";
  font-size: 2em;
  font-style: normal;

  &.is-disabled {
    cursor: not-allowed;
    color: darken($white, 25);
  }
}

.carousel__control-next::before {
  content: "\f061";
}

.carousel__control-prev::before {
  content: "\f060";
}
