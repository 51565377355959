.page-content {
  &__thumbnails {
    img {
      margin-right: 2 * $gutter;
    }

    @include tablet-landscape {
      ul.columns {
        margin-left: -3 * $gutter;
        margin-right: -3 * $gutter;
      }

      li.column {
        padding-left: 3 * $gutter;
        padding-right: 3 * $gutter;
      }
    }
  }
}
