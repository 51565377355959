$white: #fff;
$light-blue: #e0deee;
$blue: #414e86;
$blue-light: #f1f1f6;
$orange: #f89938;
$yellow: #fede10;
$dark-yellow: #fcc121;
$red: red; // error color
$blue-pink: #f2f0f8;

$grey: #414042;
$dark-grey: #231f20;
$light-grey: #9e9d9c;
$lighter-grey: #d8d8d8;

$gradient-orange: linear-gradient(to bottom, $yellow, $orange);
$gradient-orange-h: linear-gradient(to right, $orange, $yellow);

$shadow-grey: 1px 1px 3px rgba(0, 0, 0, 0.25);
