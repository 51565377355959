.page-content {
  &__featured-image {
    img {
      height: auto;
      max-width: 1020px;
      width: 100%;
    }

    .tag {
      margin-top: 2 * $gutter;

      p {
        color: $light-grey;
      }
    }
  }
}
