.page-content {
  &__share {
    border-top: 2px solid rgba($lighter-grey, 0.7);
    justify-content: flex-start;
    padding: 3 * $gutter 4em 0 0;

    .assets-list {
      margin-bottom: $gutter;

      @include tablet {
        margin-bottom: 0;
        margin-right: 2 * $gutter;
      }

      a.is-rounded {
        @include rounded(38px);
        background: $blue;
        transition: background-color ease 0.2s;

        &:hover {
          background: $dark-yellow;
        }

        .icon path {
          fill: #fff;
        }
      }
    }

    p.share-cta {
      color: $light-grey;
      font-size: 0.875em;
    }
  }
}

.is-share-block-type {
  border-top: 0;
  padding: 0;
}