$hero-height-correction: 19em;

// main navigation visible on 1024 or bigger
.home,
.toolbox {
  background: #fff;

  main {
    @include until-tablet {
      padding-top: 2 * $gutter;
    }

    @include tablet-landscape {
      margin-top: 0;
      min-height: $hero-height-correction;
    }

    .navigation-main,
    .navigation-toolbox {
      display: none;
      @include bg-blue;

      @include tablet-landscape {
        display: block;
        margin-top: -$hero-height-correction;
      }

      &__body {
        padding: 3.5em 4em;
      }
    }
  }

  .hero__container {
    height: fit-content;
  }

  @include tablet-landscape {
    .hero__container {
      height: 100vh;
      &.video-in-header {
        min-height: 60em;
      }
    }

    // hide menu toggler 1024 or bigger
    .navbar__menu-toggler__label {
      display: none;
    }

    // hide menu
    .navbar__menu-toggler:checked
      ~ .navbar__container
      .navbar__menu__container {
      transform: translateY(-100%);
    }

    .navbar__menu__container {
      display: none;
    }

    // vertical center carousel content
    .carousel__slide {
      @include tablet {
        transform: translateY(-90%);
        &.has-video {
          transform: translateY(-48%);
        }
      }
    }

    .carousel__indicator__container {
      bottom: 50%;
      padding-bottom: $hero-height-correction * 0.5;
      width: auto;
      right: 0;
    }
  }

  @media only screen and (min-width: $tablet-landscape) and (max-height: 680px) {
    .hero__container {
      height: 680px;
    }
  }

  .carousel__indicator__container {
    justify-content: flex-start;
  }

  .carousel__control__container {
    display: none;

    @include desktop {
      display: block;
    }
  }

  main {
    margin-top: 0;
  }

  .footer {
    .footer-main {
      .column {
        &:last-of-type {
          display: none;

          @include tablet-landscape {
            display: block;
          }
        }
      }
    }
  }
}

.toolbox-page {
  .navbar__container .has-text-right {
    display: none;
  }
  @include tablet {
    background: $light-blue;
  }

  h2:not(.toolbox-intro__main-content__title):not(.title) {
    font-size: 1.4em;
    font-family: $font-primary-semibold;

    @include tablet {
      font-size: 1.5em;
    }
  }
}
