@use "sass:math";

$image-mobile: 60px;
$image-tablet: 75px;
$image-tablet-landscape: 92px;

.block {
  .author {
    &__container {
      display: inline-block;
      background: $dark-yellow;
      color: $grey;
      font-size: 0.75em;
      margin-right: math.div($image-mobile, 3);
      padding: $gutter 5 * $gutter $gutter 2 * $gutter;
      position: absolute;
      right: 0;

      &.is-top {
        top: 0;
        transform: translateY(-50%);

        @include desktop {
          transform: none;
        }
      }

      &.is-bottom {
        bottom: 0;
        display: none;

        @include desktop {
          display: block;
        }
      }

      @include tablet {
        transform: translateY(0);
        font-size: 1em;
        padding-right: 5 * $gutter;
        margin-right: math.div($image-tablet, 3);
      }

      @include tablet-landscape {
        padding-right: 6 * $gutter;
        margin-right: math.div($image-tablet-landscape, 3);
      }

      @include desktop {
        margin-right: 0;
      }
    }

    &__name,
    &__role {
      color: $grey;
      line-height: 1.3em;
    }

    &__name {
      font-size: 1.125em;
    }

    &__role {
      font-size: $font-size-small;
    }

    &__img {
      position: absolute;
      height: $image-mobile;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: $image-mobile;

      > img {
        @include rounded(100%);
        object-fit: cover;
        transform: translateX(calc(100% / 3));
      }

      @include tablet {
        height: $image-tablet;
        width: $image-tablet;
      }

      @include tablet-landscape {
        height: $image-tablet-landscape;
        width: $image-tablet-landscape;
      }
    }
  }
}
