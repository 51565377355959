.block {
  &__media {
    .column {
      order: 0;

      &.is-media {
        @include until-tablet {
          order: 1;
        }
      }

      &:first-of-type {
        @include tablet {
          padding-right: $block-spacing;
        }
      }

      &:last-of-type {
        @include tablet {
          padding-left: $block-spacing;
        }
      }

      &.is-4\/12 + .is-8\/12,
      &.is-8\/12 + .is-4\/12 {
        @include tablet {
          border-left: 1px solid $lighter-grey;
        }
      }

      .tag-container {
        margin-bottom: 2 * $gutter;
      }
    }
  }
}
