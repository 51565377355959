.card {
  &.is-3by5 {
    padding-top: 60%;
  }

  &.is-2by3 {
    padding-top: 66.66%;

    .card__title {
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
    }
  }

  a {
    &:before {
      background: transparent;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
      bottom: 0;
      content: "";
      height: 10em;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  }

  &__title {
    bottom: 0;
    color: #fff;
    font-size: 1.2em;
    margin: 1.5 * $gutter;
    position: absolute;
    z-index: 2;

    @include mobile-landscape {
      font-size: 1.5em;
    }
  }

  &__date {
    background: #fff;
    color: $dark-grey;
    font-family: $font-primary-bold;
    font-size: 0.75em;
    padding: $gutter 1.5 * $gutter;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }
}

.card-block {
  width: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;

  &__visual {
    width: 100%;
  }

  &__content {
    padding-top: $gutter;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 1.56em;
  }
}

.text-image-block {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
  }

  &__content {
    padding-top: $gutter * 0.5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @include tablet {
      padding-top: 0px;
      @include flex-percentage(12, 7);
    }
  }

  &__visual {
    order: -1;
    width: 100%;
    @include tablet {
      order: 0;
      @include flex-percentage(12, 4);
    }
  }
}
