$navbar-height: 101px;
$navbar-height-desktop: 108px;
$topbar-height: 28px;

.navbar {
  background: none;
  padding: 4 * $gutter $gutter $gutter * 2;

  @include desktop {
    padding: 4 * $gutter $gutter * 2 $gutter * 2;
  }

  &:before {
    @include bg-blue-navigation(0 #{-$topbar-height});
    content: "";
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity ease-in-out $transition-fast;
    width: 100%;

    @include desktop {
      @include bg-blue-navigation(
        0 #{-$topbar-height},
        "/assets/images/bg_darkblue_highres.jpg"
      );
    }
  }

  &.with-background {
    &:before {
      opacity: 1;
    }
  }
}

.navbar__container {
  left: 0;
  position: absolute;
  text-shadow: $shadow-grey;
  top: $topbar-height;
  width: 100%;
  z-index: 10;

  //Background behind the active topbar item ("algemeen" or "toolbox")
  &:before {
    content: "";
    position: absolute;
    top: -$topbar-height;
    left: 0;
    height: $topbar-height;
    width: 100%;

    opacity: 0;
    transition: opacity ease-in-out $transition-fast;
    @include bg-blue-navigation;

    @include desktop {
      @include bg-blue-navigation(
        0 0,
        "/assets/images/bg_darkblue_highres.jpg"
      );
    }
  }

  &.is-expanded {
    &:before {
      opacity: 1;
    }
  }

  a {
    align-items: center;
    color: $white;
  }

  @include desktop {
    a,
    .navbar__menu-toggler__label span {
      font-size: 1.125em;
    }
  }
}

.navbar.columns {
  margin-bottom: 0;
}

.navbar__section,
.navbar__branding__container {
  z-index: 10;
  align-self: center;
}

.navbar__branding__container {
  flex: 2;
}

.navbar__branding {
  display: block;
  width: 130px;
  @media only screen and (min-width: 350px) {
    width: 180px;
  }
}

.navbar__container:not(.is-expanded) {
  .navbar__branding {
    transition: width ease-in-out $transition-fast;
  }
}

.navbar__menu__container {
  height: calc(100% - #{$navbar-height});
  left: 0;
  opacity: 0;
  padding: 0 2 * $gutter;
  position: fixed;
  right: 0;
  top: 0;
  margin-top: calc((100% + #{$navbar-height}) * -1);
  transition: opacity ease-in-out $transition-fast;
  z-index: 9;
  box-shadow: 0 50px 50px rgba(0, 0, 0, 0.5);

  @include desktop {
    height: calc(100% - #{$navbar-height-desktop});
    margin-top: calc((100% + #{$navbar-height-desktop}) * -1);
  }

  &.with-background {
    @include bg-blue-navigation(0 $navbar-height * -1 - $topbar-height);

    @include desktop {
      @include bg-blue-navigation(
        0 $navbar-height-desktop * -1 - $topbar-height,
        "/assets/images/bg_darkblue_highres.jpg"
      );
    }
  }
}

.navbar__menu {
  padding: $gutter 0;
  width: 100%;
}

.navbar__menu-toggler {
  display: none;
}

.navbar__menu-toggler__label {
  color: $white;
  cursor: pointer;
  display: inline-block;
  padding: $gutter;
  border-left: 1px solid white;
  min-width: 110px;

  &::before {
    content: "\f054";
    position: relative;
    left: 5px;
    display: inline-block;
    font-family: "Font Awesome 5 Pro";
    font-size: 1.125em;
    font-style: normal;
    height: 1em;
    padding-right: 0.5em;
    text-align: left;
    transform: rotate(90deg);
    transform-origin: 0.25em center;
    transition: transform ease-in-out $transition-fast;
    width: 1em;
  }
}

.navbar__menu-toggler__close-text {
  display: none;
}

// expanded menu
.navbar__container.is-expanded {
  position: fixed;

  .navbar__menu-toggler__open-text {
    display: none;
  }

  .navbar__menu-toggler__close-text {
    display: inline-block;
  }

  .navbar__menu-toggler__label::before {
    transform: rotate(270deg);
  }

  .navbar__menu__container {
    -ms-overflow-style: none;
    margin-top: $navbar-height;
    opacity: 1;
    overflow-y: auto;

    @include desktop {
      margin-top: $navbar-height-desktop;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.navbar__container .logo {
  width: 160px;
  max-width: 100%;
}

// BREAKPOINTS
@include tablet {
  .navbar__menu__container {
    position: absolute;
    height: auto;
  }

  .navbar__branding__container {
    flex: 0 0 auto;
    padding-right: 2 * $gutter;
  }

  .navbar__section:last-child > a {
    margin-left: $gutter;
  }

  .navbar__menu-toggler__label {
    padding-left: 3 * $gutter;
    padding-right: 3 * $gutter;
  }

  .navbar__container .logo {
    width: auto;
  }
}

@include desktop {
  .navbar__menu {
    flex: none;
    width: calc(100% - 216px);
  }
}

.navbar__container.is-scrolled:not(.is-expanded) {
  border: 0;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-image: $gradient-orange-h;
  border-image-slice: 1;
  position: fixed;
  top: 0;

  .navbar {
    padding-bottom: $gutter;
    padding-top: $gutter;
    padding-top: 40px;
  }

  .navbar__branding {
    height: 40px;
  }

  .logo {
    width: auto;
    max-width: initial;
    height: 100%;
  }

  @include desktop {
    .navbar__menu {
      width: calc(100% - 88px);
    }
  }
}
