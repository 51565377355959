.breadcrumb__container {
  display: inline-block;
}

.breadcrumb__list {
  display: flex;
}

.breadcrumb__item {
  display: flex;
  
  &:not(:first-child)::before {
    content: "\\\\";
    color: $grey;
    padding: 0 0.5 * $gutter;
  }
}

.breadcrumb__item-link {
  text-decoration: underline;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.block.with-background .breadcrumb__item {
  &::before,
  .breadcrumb__item-link {
    color: $white;
  }
}
