.toolboxHome {
  .hero__container {
    @include tablet {
      margin-bottom: calc(15em - 3.5em);
    }
    @include desktop {
      height: 380px;
      margin-bottom: calc(30em - 3.5em);
    }
  }
}

.toolbox-hero__container {
  display: flex;
  height: 100%;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.toolbox-hero__content__container {
  display: inline-block;
  flex: none;
  height: 100%;
  width: 100%;
}

.toolbox-hero__content {
  margin: 0 auto;
  max-width: $content-max-width-desktop;
  // max-width: 100%;
  overflow: hidden;
  padding: $gutter;
  transform: translateY(-50%);
  top: 50%;
  @include tablet {
    margin: 0 0;
    max-width: $content-max-width-desktop;
  }
}

.toolbox-hero__title {
  font-size: 1.5em;
  text-shadow: $shadow-grey;
  white-space: pre-wrap;

  @include mobile-landscape {
    font-size: 2.25em; // ie fallback
    font-size: clamp(1.5em, 3vw, 3em);
  }
}

.toolbox-hero__indicator__container {
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 2 * $gutter $gutter;
  position: absolute;
  width: 100%;

  @include tablet-landscape {
    flex-direction: column;
  }
}
