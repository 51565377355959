.page-content {
  &__related-news {
    display: inline-block;
    border-bottom: 2px solid rgba($lighter-grey, 0.7);
    padding: 0 4em 1.5 * $gutter 0;

    a {
      text-decoration: none;

      &:hover span {
        text-decoration: underline;
      }
    }

    img {
      margin-right: $gutter;

      @include until-tablet {
        margin-bottom: $gutter;
      }
    }
  }
}
