$arrow-left-width: 1.25em; // padding + width of arrow-left::before

@mixin arrow-left {
  &::before {
    content: "\f061";
    display: inline-block;
    font-family: "Font Awesome 5 Pro";
    font-size: 1.125em;
    padding-right: 0.25em;
    transform: rotate(45deg) translateY(0.125em);
    transform-origin: 0.5em center;
    transition: transform $transition-fast;
    width: 1em;
  }

  &:hover,
  &:active,
  &:focus {
    &::before {
      transform: rotate(0deg);
    }
  }
}

@mixin bg-blue {
  background-color: $blue;
  background-image: url("/assets/images/bg_darkblue_highres.jpg");
  background-position: center;
  background-size: cover;
}

@mixin bg-blue-navigation($pos: 0 0, $image: "/assets/images/bg_darkblue_highres_nav.jpg") {
  background-color: $blue;
  background-image: url($image);
  background-position: $pos;
  background-size: 100%;
}

@mixin rounded($size) {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: $size;
  justify-content: center;
  width: $size;
}

$gradient: darken($light-blue, 2%) 1px, transparent 1px;

@mixin gitter-bg {
  background: $light-blue;
  background-size: 125px 125px, 125px 125px, 25px 25px, 25px 25px;
  background-image: linear-gradient($gradient), linear-gradient(90deg, $gradient), linear-gradient($gradient),
    linear-gradient(90deg, $gradient);
}

// let's say you have a 12 column grid and you want your element to be 3 column of that, it's not a perfect 33%; (this removes the gap + fallback on IE)
@mixin flex-percentage($parent-total, $column-amount, $spacing: $gutter) {
  $w: columnWidth($parent-total, $column-amount, $spacing);
  flex: 0 0 $w;
  width: $w;
  @include IE {
    max-width: $w;
  }
}

@mixin IE {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
