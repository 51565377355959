.hero__container {
  background: $light-blue;
  color: $white;
  height: 260px;
  overflow: hidden;
  position: relative;
  width: 100%;

  @include tablet {
    height: 380px;
  }

  @include tablet-landscape {
    height: 680px;
  }

  &:before {
    background: transparent;
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0));
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 150px;
    z-index: 1;
  }
}

.hero__title {
  font-size: 2.25em;
}
