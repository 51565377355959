.image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%; 
  overflow: hidden;
  
  & > img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
